.apexcharts-legend-text{
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #1F1F1F !important;
  line-height: 34px;
  font-family: Poppins !important;
}
.chart-heading{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D6D6D6;
}
.chart-heading p{
    color: #9291A5;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px; 
    margin-bottom: 0px;
}
.chart-heading h3{
    color: #1F1F1F;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    padding-bottom: 10px;
}
.chart-heading .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 4px;
background: #F3F5F6;
}
.chartBg{
    border-radius: 4px;
    border: 1px solid #D6D6D6;
    background: #FFF;
    padding: 23px;
}
.apexcharts-legend {
    margin-top: 53px;
}
.sample-but  a{
    text-decoration: none;
    color: #1C1C1C;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    border-radius: 12px;
    background: #33DABD;
    padding: 8px;
}
.ant-pagination{
    text-align: center;
}
.ant-pagination .ant-pagination-item a {
    color: #1C1C1C !important;
    font-size: 16px;
    font-weight: 600;
    
}
.ant-pagination .ant-pagination-item-active a {
    border-radius: 4px;
background: #33DABD;
color: #FFF !important;
border: 0px;

}
.ant-pagination .ant-pagination-item-active{
    border-color: transparent !important;
}
.ant-pagination .ant-pagination-prev span{
width: 0px;
}
.ant-pagination .ant-pagination-next span{
    width: 0px;
}
.ant-pagination-prev{
background-image: url("../../public/Images/arrow-leftt.svg");
background-size: cover;
background-repeat: no-repeat;
position: relative;
right: 20px;
}
.ant-pagination-next{
    background-image: url("../../public/Images/arrow-right.svg");
    background-size: cover; 
    background-repeat: no-repeat;
position: relative;
left: 20px;
}
.clothes-im {
    width: 100%;
    height: 412px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 13px;
}
.image-gallery .row>* {
    padding-right: calc(var(--bs-gutter-x) * .0);
}

/* relevant styles */
.img__wrap {
  position: relative;
  height: auto;
 margin-bottom: 15px;
}

.img__description_layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #3f4a59ca;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  padding: 10px 10px;
  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}
.list_flex{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.list_box_user{
  max-width: 75px;
  min-width: 75px;
}
.img__wrap:hover .img__description_layer {
  visibility: visible;
  opacity: 1;
}

.img__description {
  transition: .2s;
  transform: translateY(1em);
  overflow: auto;
  height: 408px;
  overflow-x: hidden;
  padding: 10px;
}
.persion-scroll{
  overflow:auto;
  overflow-x:hidden;
  height:400px;
}
.img__description::-webkit-scrollbar {
  width: 6px;
}
.img__description::-webkit-scrollbar-thumb {
  background: #9197B3;
  border-radius: 5px;
}

.img__wrap:hover .img__description {
  transform: translateY(0);
}


  .img__description h6{
    color: #FFF;
    font-size: 8px;
    font-weight: 600;
    line-height: 16px; 
    padding-bottom: 6px;
    margin-bottom: 0px;
  }
  .img__description p{
    color: #FFF;
    font-size: 8px;
    font-weight: 500;
    line-height: 16px; /* 200% */
    margin-bottom: 0px;
    padding-top: 6px;
    padding-bottom:4px;
  }
  .msg-noti-2{
    width: 12px;
    height: 11.979px;
    color: #1C1C1C;
    font-size: 6px;
    font-weight: 600;
    border: 1px solid #fff;
    background-color: #33DABD;
    border-radius: 16px;
    position: absolute;
    text-align: center;
    left: -4px;
  }
  .t-pro img{
    margin-right: 5px;
  }
  .border-bottom-tooltip{
    border-bottom: 1px solid #D6D6D6;
    opacity: 0.2;
  }
  .t-pro{
    position: relative;
    display: flex;
    min-height: 33px;
    max-height: 33px;
    margin-bottom: 10px;
    max-width: 170px;
    min-width: 170px;
  }
  .t-pro h5{
    color: #FFF;
    font-size: 10px;
    font-weight: 700;
    margin-bottom:0px;
    line-height: 16px; /* 160% */
  }
  .t-pro h6{
font-size: 8px;
font-weight: 400;
line-height: 16px;
padding-bottom: 6px;
margin-bottom: 0px;
  }
  .three_but{
    display: flex;
    justify-content: space-around;
  }
  .three_but a{
    border-radius: 4px;
    background: #FFF;
    color: #3F4A59;
    font-size: 8px;
    font-weight: 700;
    line-height: 16px; /* 200% */
    text-decoration: none;
    padding: 8px 18px;
  }
  .month_name{
    display: flex;
  }
  .month-box{
    border-radius: 4px 0px 0px 0px;
    background: #3F4A59;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
padding: 8px;
  }
  .month-box.active{
    background: #33DABD !important;
  }
  .month-box.active h6{
    color: #fff !important;
    
  }
  .month-box.active svg{
    fill: #fff !important;
  }
  .month-box h6{
    color: #FFF;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px; 
  text-align: center;
  }
  .pending-Link{
    border-radius: 10px;
    background: #F00; 
    min-width: 83px;
    max-width: 83px;
    height: 20px;
    color: #FFF;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    padding: 2px 10px 6px 10px;
    text-decoration: none;
    text-align:center;
  }
  .pending-Link:hover{
    color: #fff;
  }

.workload-filter{
  display: flex;
  padding: 10px 0px 30px 0px;
  justify-content: space-around;
}
.workload-filter img{
  width: 64px;
height: 64px;
border-radius: 64px;
object-fit: cover;
}
.workload-filter h6{
  color: #1C1C1C;
  font-size: 10px;
  font-weight: 700;
  width: 70px;
    text-align: center;
  line-height: 12px;
}
.fllter-con{
  text-align: center;
  height: auto;
  padding-bottom: 15px;
}
.fllter-con a{
  text-decoration: none;
  color: #FFF;
text-align: center;
font-size: 10px;
font-weight: 700;
line-height: 12px;
padding: 12px 15px;
border-radius: 16px;
background-color: #1C1C1C;
margin: 5px 0px 0px 0px;
display: inline-block;
}
.fllter-con-submisson a{
  text-decoration: none;
  color: #FFF;
text-align: center;
font-size: 10px;
font-weight: 700;
line-height: 12px;
padding: 12px 15px;
border-radius: 16px;
background-color: #1C1C1C;
margin: 5px 0px 0px 0px;
display: inline-block;
}
.fllter-con-submisson .user_image_box{
  font-size:13px;
}
.filter-con-with-user {
  height: auto;
}

.filter-con-without-user {
  height: 150px; /* ya agar aap chahein to height property hata den */
}
.padding_right{
padding-left: 20px;
padding-right: 20px;
}
.filter-border{
  border-bottom: 1px solid#D6D6D6;
}
.person-modal .modal-heading h4 {
  text-align: center;
}
.heading h2{
  color: #1C1C1C;
font-size: 28px;
font-weight: 600;
}
.t-pro img{
  width: 24px;
height: 23.959px;
border-radius: 24px;
}
.loader-load{
  position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffffc4;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
}
.all-pending-list{
  height: 119px;
}
.month_name{
  border-radius: 10px;
background: #E9E9E9;
color: #1C1C1C;
font-family: Poppins;
font-size: 10px;
font-weight: 600;
max-width: 90px;
height: 19px;
min-width: 90px;
    text-align: center;
    display: block;
    float: right;
    padding: 3px 0px;
}
@media (min-resolution: 140dpi) and (max-resolution: 150dpi){
  .img__description_layer {
    padding: 20px;
 
  }
  .t-pro h5 {
    color: #FFF;
    font-size: 7px;}
    .img__description h6{
      font-size: 7px;
    }
    .three_but a {
      padding: 8px 10px;
    }

}

.traill-text h6{
  font-size: 9px;
  font-weight: 600;
  color: #3F4A59;
  margin-bottom: 0px;
}
.blue_down a{
  padding: 12px 15px;
}
.delay_label{
  font-weight: 600;
  font-size: 14px;
}