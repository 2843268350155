.loginBg{
    min-height:100%;
    background:url("../../../public/Images/login-bg.svg");
    background-size:cover;
    height:100vh;
    display: flex;
    align-items: center;
}
.login-form{
    max-width: 360px;
    min-width: 356px;
    /* min-height: 447px;
    max-height: 447px; */
    margin: 0 auto;
    flex-shrink: 0;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 16px 35px 0px rgba(0, 0, 0, 0.10), 0px 64px 64px 0px rgba(0, 0, 0, 0.09), 0px 143px 86px 0px rgba(0, 0, 0, 0.05), 0px 255px 102px 0px rgba(0, 0, 0, 0.01), 0px 398px 111px 0px rgba(0, 0, 0, 0.00);
    padding: 22px;
}
.logo-im img{
    text-align: center;
    margin: 0 auto;
    display: block;
    padding: 15px 0px;
}
.input-box{
    border-radius: 2px;
    background: #F3F5F6;
    height: 48px;
    border: 0px;
    font-size: 14px;
    width: 100%;
    padding: 10px;
}
.login-form h1{
    color: #1C1C1C;
text-align: center;
font-size: 32px;
font-weight: 600;
}
.login-form small{
    color: #1C1C1C;
font-size: 12px;
font-weight: 400;
text-align: center;
display: block;
}
.login-form label{
color: #1C1C1C;
font-size: 14px;
font-weight: 400;
}
.login-but{
    border-radius: 4px;
background: #33DABD;
border: 0px;
color: #1C1C1C;
font-size: 15px;
font-weight: 700;
text-transform: uppercase;
max-width: 154px;
min-width: 154px;
height: 48px;
display: block;
margin: 0 auto;
}
.login-form span{
    font-size: 10px;
    color: red;
}
.logout-profile button{
    border: 0px;
    background-color: transparent;
    color: #1C1C1C;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 12px;
}