.sidebar_bg{
    background: #3F4A59;
    width: 20%;
  }
  .li-content a{
    display: flex;
    color: #FFF;
  font-weight: 500;
  text-decoration: none;
  align-items: center;
  margin: 13px 0px;
      padding: 10px 25px;
  }
  .border_bootom{
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    margin: 0px 45px;
  }
  .sidbaar-scroll {
    /* padding: 15px 22px; */
    position: fixed;
    background: #3F4A59;
    width: 20%;
    height: 100%;
    z-index: 99;
  }
  .li-content a h5{
    font-size: 14px !important;
    margin-bottom: 0px;
    margin-left: 7px;
  }
  .li-content a svg{
    fill: #FFF;
  }
  .activeClass {
    border-radius: 4px;
  background: #33DABD;
    fill: #3F4A59 !important;
    color: #3F4A59 !important;
  
  }
  .activeClass svg{
    fill: #3F4A59 !important;
  }
  .logo-im-sidebbar{
    text-align: center;
    margin: 0 auto;
    padding: 21px 0px 13px 0px;
  }
  .header-bg{
    background-color: #FFF;
    padding: 10px 34px;
    display: flex;
    justify-content: space-between;
    align-items:center;
  }
  .logout-profile {
    display: flex;
    align-items: flex-start;
  }
  .logout-profile h6{
    margin-bottom: 0px;
    color: #1C1C1C;
  font-size: 16px;
  font-weight: 600;
  padding-top: 6px;
      padding-left: 10px;
  }
  .logout-profile p{
    color: #1C1C1C;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-transform: uppercase;
  }
  .logout-profile img{
    border-radius: 50px;
    width: 50px;
    height: 50px;
  }
  .left-side-scroll{
    padding: 15px 22px;
    overflow: auto;
overflow-x: hidden;
max-height: 560px;
height: 100%;
  }
  .left-side-scroll::-webkit-scrollbar {
    width: 6px;
}
.left-side-scroll::-webkit-scrollbar-thumb
{
    background-color: #d5d5d5;
}
  @media (min-resolution: 140dpi) and (max-resolution: 150dpi){
    .li-content a h5 {
      font-size: 12px !important;
    }
  }