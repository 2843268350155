.download-but button{
    border: 0px;
    border-radius: 4px;
    background: #00BB5A;
    color: #FFF;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 20px;
    margin-right: 10px;
}
.download-but{
    display: flex;
    align-items: center;
}
.pending-table-img{
    display: flex;
    align-items:center;
    justify-content: center;
}
.pending-table-img img{
    width: 28px;
height: 36px;
border-radius: 8px;
margin-right: 6px;
}
.Zindex-class img{
    position: relative;
    z-index: 1090;
}
.Zindex-class{
    position: relative;
    z-index: 1090;
    margin: 0 auto;
    text-align: center;
}




/*  */
.team-pic {
	position:relative;	
    cursor: pointer;
}


/* Tooltip text */
.team-member .tooltiptext {
    visibility: hidden;
    width: 170px;
    /* background-color: #3F4A59; */
    color: #fff;
	line-height:20px;
    text-align: center;
    padding: 5px 0;
 
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
	top: 100%;
    left: 50%;
    margin-left: -105px;
	
	opacity: 0;
    transition: opacity 1s;
	
}


/* Show the tooltip text when you mouse over the tooltip containerRadio */
.team-member:hover .tooltiptext {
    visibility: visible;
	opacity: 1;
}

/********  Code for smaller image with red overlay 210 x 150 ********/

/* .bg-image-sm {
	display:inline-block;
	max-width:210px;
	max-height:150px;
	padding-right:15px;
} */
.tooltiptext-sm a{
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    border-bottom: 1px solid rgba(214, 214, 214, 0.16);
    line-height: 16px;
    display: block;
    padding: 10px;
    margin: 0px 10px;
    text-decoration: none;
}

/* Tooltip text */
.bg-image-sm .tooltiptext-sm {
    visibility: hidden;
    width: 170px;
    background-color: #3F4A59;
    color: #fff;
	line-height:20px;
    text-align: center;
    padding: 5px 0;
	opacity: 0;
 
/* Position the tooltip text - see examples below! */
    position: absolute;
    top: 124%;
    left: 50%;
    margin-left: -144px;
	opacity: 0;
    transition: opacity 1s;
	z-index: 9999;
	
}


/* Show the tooltip text when you mouse over the tooltip container */
.bg-image-sm:hover .tooltiptext-sm {
    visibility: visible;
	opacity: 1;
}

.bg-image-sm .tooltiptext-sm::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: 47px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent #3F4A59 transparent;
}

/* submison */
.submission-modal p{
    color: #1C1C1C;
text-align: center;
font-size: 16px;
font-weight: 500;
padding: 20px 0px 7px 0px;
}
.submission-modal button{
    width: 152px;
height: 44px;
    border-radius: 4px;
background: #33DABD;
border: 0px;
color: #1C1C1C;
font-size: 15px;
font-weight: 700;
text-transform: uppercase;
text-align: center;
margin: 0 auto;
display: block;
}
.all-pending-employee{
    display: flex;
    align-items: center;
}
.all-pending-employee h6{
    padding-left: 2px;
}
.all-pending-employee img{
    width: 32px;
height: 32px;
border-radius: 32px;
}
/* .hover-box:hover{
    border-radius: 4px;
border: 2px solid #F61111;

background: rgba(246, 17, 17, 0.10);
} */
.high-priority {
    border-radius: 4px;
    border: 2px solid #F61111;
    
    background: rgba(246, 17, 17, 0.10);
  }
.fllter-con img{
    width: 64px;
height: 64px;
border-radius: 64px;
object-fit: cover;
}
.fllter-con h6{
    color: #1C1C1C;
    margin: 0 auto;
text-align: center;
font-size: 10px;
font-weight: 700;
}
.fllter-con-submisson img{
    width: 64px;
    height: 64px;
    border-radius: 64px;
    object-fit: cover;
}
.fllter-con-submisson h6{
    color: #1C1C1C;
    margin: 0 auto;
text-align: center;
font-size: 10px;
font-weight: 700;
}
.padding-section{
    padding: 8px 0px 30px 0px;
}

/* node */
.circle-bg{
    margin-bottom: 20px;
    background-color: #D9D9D9;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin: 0 auto;
}
.node-default{
    position: relative;
    text-align: center;
}
.border-right{
    height: 1px;
    width: 100px;
    border-bottom: 2px solid #3F4A59;
    position: absolute;
    top: 56px;
    left: 84%;
}

.circle-bg img {
    position: absolute;
    bottom: -8px;
    right: 53px;
}
.pattern-design img{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.view_im img{
    width: 40.444px;
height: 52px;
border-radius: 4px;
}
.view_a a{
    border-radius: 12px;
background: #33DABD;
/* width: 102px; */
height: 24px;
color: #1C1C1C;
display: block;
text-align: center;
font-family: Poppins;
font-size: 12px;
font-weight: 600;
margin: 0 auto;
text-decoration: none;
line-height: 16px; /* 133.333% */
padding: 3px;
}
.hour_workload{
    color: rgba(28, 28, 28, 0.40);
font-family: Poppins;
font-size: 10px;
font-weight: 500;
line-height: 12px; /* 120% */
}

.containerRadio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .containerRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark_r {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-image: url("../../public/Images/radio.svg");
    background-size: cover;
    border-radius: 50%;
  }
  
 
  /* When the radio button is checked, add a blue background */
  .containerRadio input:checked ~ .checkmark_r {
   background-image: url("../../public/Images/fillRadio.svg");
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark_r:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .containerRadio input:checked ~ .checkmark_r:after {
    display: block;
  }
  
 .Requestpending{
    color:#ffd966;
 }
 .RequestAccepted{
 color: #2ED47E;
 }
 .RequestDecline{
    color: #FF0000;
 }